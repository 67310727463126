import React, { useState, useRef, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../context/AppContext';

import axiosInstance from '../api/AxiosConfig';

const ModalSendDescription = () => {
    const { t } = useTranslation();

    const {
        openDescriptionModal,
        setOpenDescriptionModal,
        uuid,
        showAlert
    } = useContext(AppContext); // Získání stavu a setteru z kontextu

    const dialogRef = useRef(null); // Ref pro dialog
    const [descValue, setDescValue] = useState(''); // Stav pro popis
    const [maxLength] = useState(10000); // Maximální počet znaků (statická hodnota, nepotřebuje update)

    // Otevření nebo zavření dialogu na základě hodnoty v contextu
    useEffect(() => {
        if (openDescriptionModal && dialogRef.current) {
            dialogRef.current.showModal();
        } else if (!openDescriptionModal && dialogRef.current) {
            dialogRef.current.close();
        }
    }, [openDescriptionModal]);

    // Funkce pro zavření dialogu a aktualizaci contextu
    const closeDialog = () => {
        setOpenDescriptionModal(false); // Nastavení contextu na false pro zavření dialogu
    };

    const onUpdateDesc = async (e) => {
        e.preventDefault()
        e.target.blur()
        
        try {
            const onUpdateDescResponse = await axiosInstance.patch(`shop/update/${uuid}/`, {
                description: descValue
            })
            showAlert(t("appSettings.infoMessages.updateDescSuccessInfoMsg", "info"))
        } catch (error) {
            console.log("Update description error: ", error)
            showAlert(t("appSettings.errorMessages.updateDescErrorMsg"), "danger")
        } finally {
            closeDialog()
        }
    }

    return (
        <div>
            <dialog ref={dialogRef} className='description__modal'>
                <div className="box">
                    <h2>{t('appSettings.descTitle')}</h2>
                    <form onSubmit={onUpdateDesc} autoComplete="off" className="form">
                        <label htmlFor="about-us">
                            {t("descriptionModal.info")}
                        </label>
                        <textarea
                            id="about-us"
                            rows="5"
                            cols="40"
                            placeholder={t("registerForm.descPlaceholder")}
                            maxLength={maxLength}
                            minLength={350}
                            value={descValue}
                            style={{ resize: "none" }}
                            onChange={(e) => setDescValue(e.target.value)}
                        />
                        <p style={{ textAlign: "right", margin: 0 }}>{descValue.length}/{maxLength} {t("appSettings.numOfLetters")}</p>
                        <div className="btn_modal_wrapper" style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px"}}>
                            <button className="btn" onClick={closeDialog}>
                                {t('descriptionModal.close')}
                            </button>
                            <button type="submit" className="btn">{t('appSettings.sendDesc')}</button>
                        </div>
                    </form>

                    {/* Tlačítko pro zavření dialogu */}
                </div>
            </dialog>
        </div>
    );
};

export default ModalSendDescription;
