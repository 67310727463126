import React from 'react'

const Blog = () => {
    return (
        <section className="section">

            <article className="container container--short">

                <h1 className="title">Blog</h1>

                <div className="articles articles--hero">

                    <a href="#" className="box article articles__1">

                        <p className="article__image"><img src="/img/placeholder/article-image.jpg" alt="" width="600" height="248" loading="lazy" /></p>

                        <div className="article__content">

                            <p className="article__meta">
                                <span className="article__tag">PPC</span>
                                13. 2. 2024
                            </p>
                            <h3>Jak využít umělou inteligenci v PPC?</h3>
                            <p>Základem úspěšných PPC kampaní je efektivita. A ať chceme nebo ne, maximální efektivity reklam v dnešním rychlém e-commerce světě přeplněném konkurencí bez automatizace a pokročilé analýzy dat nedosáhneme. Zde přichází na scénu umělá inteligence (AI), která v posledních letech výrazně mění způsob, jakým digitální marketing funguje. AI technologie dokáží nejen optimalizovat kampaně v reálném čase, ale také předpovídat chování zákazníků ...</p>
                            <p><button className="btn">Pokračovat ve čtení</button></p>

                        </div>

                    </a>

                    <a href="#" className="box is-connected article articles__2">

                        <p className="article__image"><img src="/img/placeholder/article-image.jpg" alt="" width="600" height="248" loading="lazy" /></p>

                        <div className="article__content">

                            <p className="article__meta">
                                <span className="article__tag">Případová studie</span>
                                9. 2. 2024
                            </p>
                            <h3>Case study: Levnepleny.cz získaly po přechodu na PermoniQ vyšší výkon</h3>

                        </div>

                    </a>

                    <a href="#" className="box is-connected article articles__3">

                        <p className="article__image"><img src="/img/placeholder/article-image.jpg" alt="" width="600" height="248" loading="lazy" /></p>

                        <div className="article__content">

                            <p className="article__meta">
                                <span className="article__tag">Aktuality</span>
                                <span className="article__tag">PPC</span>
                                2. 2. 2024
                            </p>
                            <h3>Sklik 2023 v oparu novinek a trendů</h3>

                        </div>

                    </a>

                </div>

            </article>

        </section>
    )
}

export default Blog