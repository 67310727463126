import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import ModalContactUsStyles from "../css/ModalContactUsStyles.module.css";
import { sendMessage } from '../utils/axios_functions/sendMessage';
import SimpleAlert from './SimpleAlert';
import ModalLoading from './ModalLoading';
import { useTranslation } from 'react-i18next';

const ModalContactUs = ({ setMessage }) => {
    const { t } = useTranslation()

    const {
        userData,
        selectedShop,
        isAlertVisible,
        showAlert,
        closeAlert,
        alertMessage,
        isUserDataLoading,
        setIsUserDataLoading
    } = useContext(AppContext);

    const [openContactUsModal, setOpenContactUsModal] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setOpenContactUsModal(false);
            }
        };

        if (openContactUsModal) {
            document.body.style.overflow = "hidden";
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [openContactUsModal]);

    const handleBackdropClick = (e) => {
        if (e.target.classList.contains(ModalContactUsStyles.contact_us_modal_layout)) {
            setOpenContactUsModal(false);
        }
    };

    const onContactMe = async () => {
        if (!userData) return;
        setMessage("Odesílám vaši zprávu.")
        setIsUserDataLoading(true)
        const baseMessageObject = {
            email: userData.email,
            subject: "PermoniQ - kontaktujte mě!",
            message: `
                Žádost o pomoc<br>
                e-mail: ${userData.email}<br>
                time: ${new Date().toLocaleString("cs-CZ")}<br>
                URL: ${window.location.pathname}
            `,
            send_email: true,
            country: window.location.pathname.split("/")[1],
        };

        const shopDetails = selectedShop
            ? {
                UUID: selectedShop.uuid || "",
                name: selectedShop.name || "",
                website: selectedShop.website || "",
                phone: selectedShop.phone || "",
                first_name: selectedShop.first_name || "",
                last_name: selectedShop.last_name || "",
            }
            : {};

        const messageObject = {
            ...baseMessageObject,
            message: `${baseMessageObject.message}<br>${Object.entries(shopDetails)
                .map(([key, value]) => `${key}: ${value}`)
                .join("<br>")}`,
        };

        try {
            const response = await sendMessage(messageObject);
            console.log(response.message)
            if (response?.message === "Message received!") {
                showAlert(t("modalContactUs.infoMessages.messageSentInfoMsg"), "info");
            }
        } catch (error) {
            console.log(error);
            showAlert(t("modalContactUs.errorMessages.messageSentErrorMsg"), "danger");
        } finally {
            setIsUserDataLoading(false)
            setOpenContactUsModal(false);
        }
    };

    return (
        <>
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    onClose={closeAlert}
                    type={alertMessage.type}
                />
            )}
            <div
                className={`${ModalContactUsStyles.send_message_plugin}`}
                onClick={() => setOpenContactUsModal(true)}
                aria-label="Otevřít formulář Kontaktujte nás"
                role="button"
                tabIndex="0"
            >
                <span>?</span>
            </div>
            {openContactUsModal && (
                <div
                    className={`${ModalContactUsStyles.contact_us_modal_layout}`}
                    onClick={handleBackdropClick}
                    role="dialog"
                    aria-labelledby="contactUsTitle"
                    aria-describedby="contactUsDescription"
                    aria-modal="true"
                >
                    <span
                        className={`${ModalContactUsStyles.close_btn}`}
                        onClick={() => setOpenContactUsModal(false)}
                        role="button"
                        aria-label="Zavřít formulář Kontaktujte nás"
                        tabIndex="0"
                    >
                        &times;
                    </span>
                    <div className={`${ModalContactUsStyles.contact_us_modal}`}>
                        <div className={`${ModalContactUsStyles.contact_us_wrapper}`}>
                            <h4 id="contactUsTitle">{t("modalContactUs.title")}</h4>
                            <p id="contactUsDescription">
                                {t("modalContactUs.description")}
                            </p>
                            <div className={`${ModalContactUsStyles.btn_box}`}>
                                <button
                                    className={`${ModalContactUsStyles.contact_me_btn} btn`}
                                    onClick={onContactMe}
                                    aria-label="Ozvěte se mi"
                                >
                                    {t("modalContactUs.contactMeBtn")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalContactUs;
