import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ModalWelcometStyle from "../css/ModalWelcome.module.css"

import { AppContext } from '../context/AppContext'

const ModalWelcome = () => {
    const { t } = useTranslation()

    const {
        setShowWelcomeModal
    } = useContext(AppContext)

    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = "auto"
        }
    }, [])

    const onClose = () => {
        setShowWelcomeModal(false)
    }

    return (
        <div className={ModalWelcometStyle.container}>
            <button
                className={ModalWelcometStyle.close_btn}
                onClick={onClose}
            >
                &times;
            </button>
            <div className={ModalWelcometStyle.modal_box}>
                <div className={ModalWelcometStyle.textBox}>
                    <h2 className={ModalWelcometStyle.text}>{t("modalWelcome.heading")}</h2>
                    <p className={ModalWelcometStyle.text}>{t("modalWelcome.infoText")}</p>
                    <p className={ModalWelcometStyle.text}>{t("modalWelcome.statsSoon")}</p>
                    <p className={ModalWelcometStyle.text}>{t("modalWelcome.thankYou")}</p>
                </div>
                <div className={ModalWelcometStyle.btn_box}>
                    <input
                        type="button"
                        className={`btn ${ModalWelcometStyle.btn}`}
                        value={t('modalWelcome.btn')}
                        onClick={onClose}
                    />
                </div>
            </div>
        </div>
    )
}

export default ModalWelcome