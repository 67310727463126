import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import he from 'he';

import { AppContext } from '../context/AppContext';

// Import SVG icons
import basketIcon from '../img/icons/basket.svg';
import openIcon from '../img/icons/open.svg';
import documentIcon from '../img/icons/document.svg';

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const ForWhomSection = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(AppContext);

    return (
        <section className="section" id={t('paths.forWhom')}>
            <article className="container">
                <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('forWhom.title'))}></h2>
                <div className="who">
                    <div className="box box--back who__1">
                        <span className="icon icon--basket">
                            <ReactSVG
                                src={basketIcon}
                                className="icon__svg"
                                beforeInjection={(svg) => {
                                    svg.setAttribute('fill', 'white')
                                    svg.setAttribute('width', '100%')
                                }}                            />
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.smallMediumLarge.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.smallMediumLarge.description'))}></p>
                    </div>
                    <div className="box box--back is-connected who__2">
                        <span className="icon icon--open">
                            <ReactSVG
                                src={openIcon}
                                className="icon__svg"
                                beforeInjection={(svg) => {
                                    svg.setAttribute('fill', 'white')
                                    svg.setAttribute('width', '100%')
                                }}                            />
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.newEstablished.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.newEstablished.description'))}></p>
                    </div>
                    <div className="box box--back is-connected who__3">
                        <span className="icon icon--document">
                            <ReactSVG
                                src={documentIcon}
                                className="icon__svg"
                                beforeInjection={(svg) => {
                                    svg.setAttribute('fill', 'white')
                                    svg.setAttribute('width', '100%')
                                }}
                            />
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.eshops.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('forWhom.audiences.eshops.description'))}></p>
                    </div>
                </div>
                {/* <p className="section__more">
                    <a href={`#${t('paths.tryFree')}`} className="btn" dangerouslySetInnerHTML={renderHTML(t('forWhom.ctaTryFree'))}></a>
                    <a href={`#${t('paths.consultation')}`} className="btn btn--inverse js-modal" onClick={openModal} dangerouslySetInnerHTML={renderHTML(t('forWhom.ctaConsultation'))}></a>
                </p> */}
            </article>
        </section>
    );
};

export default ForWhomSection;
