import axiosInstance from "../../api/AxiosConfig";

/**
 * Validates an ICO (Identification Code) for a given country by sending a POST request.
 *
 * This function uses an Axios instance to send a POST request to the `validate-ico/` endpoint,
 * passing the ICO and country as parameters. It returns the server's response if the request
 * is successful, or the error if it fails. Both `ico` and `country` parameters are required.
 *
 * @async
 * @function
 * @param {string} ico - The Identification Code (ICO) to be validated.
 * @param {string} country - The country code for which the ICO is being validated.
 * @returns {Promise<Object>} A promise that resolves to the response object if the request succeeds, 
 * or an error object if the request fails.
 * 
 * @example
 * // Example usage
 * import { validateIco } from './path-to-file';
 * 
 * const ico = '12345678';
 * const country = 'CZ';
 * 
 * validateIco(ico, country)
 *   .then(response => {
 *     console.log('Validation successful:', response);
 *   })
 *   .catch(error => {
 *     console.error('Validation failed:', error);
 *   });
 */
export const validateIco = async (ico, country) => {
    if (ico && country) {
        try {
            const response = await axiosInstance.post(`validate-ico/`, {
                ico,
                country
            });

            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
};
