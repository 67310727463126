import React, {useEffect, useContext} from "react";
import { useTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";

const GoogleTermsPage = () => {
  const { t } = useTranslation();

  const {
    setIsUserDataLoading
  } = useContext(AppContext)

  useEffect(() => {
    setIsUserDataLoading(false)
  }, [])

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>PermoniQ = online reklama na jeden kliQ</title>
      </Helmet>
      <section className="google-api-usage-disclosure">
        <h1>{t("googleTermsPage.title")}</h1>
        <p>
          <Trans
            i18nKey="googleTermsPage.disclosure"
            components={[
              <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="_blank"
                rel="noopener noreferrer" />]}
          />
        </p>

        <h2>{t("googleTermsPage.limitedUseTitle")}</h2>
        <p>{t("googleTermsPage.limitedUseDescription")}</p>
        <ul>
          {t("googleTermsPage.limitedUseItems", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default GoogleTermsPage;
