import React from 'react'
import { useLocation } from 'react-router-dom';

import FooterMain from '../components/FooterMain';
import FooterLogin from '../components/FooterLogin';

const Footer = () => {
    const location = useLocation()
    const { pathname } = location

    const isLogin = pathname.includes("login")

    return (
        <>
            {isLogin ? <FooterLogin /> : <FooterMain />}
        </>
    )
}

export default Footer