import React, { useEffect, useState } from 'react';
import closeIcon from "../img/icons/close.svg";

const SimpleAlert = ({ message, onClose, type = 'base', duration = 7000 }) => {
    const [progress, setProgress] = useState(100); // Počáteční šířka lišty jako procento (100%)

    useEffect(() => {
        setProgress(100); // Nastavení počáteční hodnoty na 100%

        const intervalDuration = duration / 100; // Snížení o 1 % z celkové šířky po celou dobu trvání

        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev <= 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1; // Snižujeme o 1 % při každé iteraci
            });
        }, intervalDuration);

        // Automatické zavření po uplynutí času
        const timeout = setTimeout(onClose, duration);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [message, duration, onClose]);

    const alertTypeClass = {
        success: 'alert--success',
        info: 'alert--info',
        danger: 'alert--danger',
        base: 'alert--base',
    }[type] || 'alert--base';

    // Definujeme barvy lišty, které budou kontrastovat s barvami alertu
    const barColors = {
        success: '#ffc4c4',
        info: '#009b45',
        danger: '#dda4a5',
        base: '#dedede'
    };

    const barColor = barColors[type] || '#000000'; // Výběr barvy podle typu alertu

    return (
        <div className="alerts">
            <div className={`alert ${alertTypeClass} is-open`} style={{ position: 'relative', borderRadius: '16px', overflow: 'hidden' }}>
                <div className="alert__message">
                    <p>{message}</p>
                </div>
                {/* Lišta kopíruje tvar alertu */}
                <div
                    className="progress-bar"
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        zIndex: 100000,
                        height: '10px',
                        backgroundColor: barColor,
                        width: `${progress}%`, // Dynamická šířka lišty
                        transition: 'width 0.1s linear', // Plynulý přechod šířky
                        borderBottomLeftRadius: '16px', // Zaoblení rohů
                        borderBottomRightRadius: progress === 0 ? '16px' : '0px' // Zobraz zaoblení jen pokud je šířka 0
                    }}
                />
                <div className="alert__close" onClick={onClose}>
                    <span className="icon icon--close">
                        <img src={closeIcon} className='icon__svg' alt="close icon" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SimpleAlert;




/*
Návod k použití komponenty SimpleAlert:

1. **Přidejte stav a funkce pro alert:**
   - Ve své komponentě, kde chcete zobrazovat upozornění, přidejte stav a funkce pro ovládání alertu:
     ```js
     const [isAlertVisible, setIsAlertVisible] = useState(false);
     const [alertMessage, setAlertMessage] = useState({ text: '', type: 'base' });

     const showAlert = (message, type = 'base') => {
         setAlertMessage({ text: message, type });
         setIsAlertVisible(true);
     };

     const closeAlert = () => {
         setIsAlertVisible(false);
     };
     ```

2. **Zobrazte SimpleAlert ve vaší komponentě:**
   - Použijte podmíněné zobrazení komponenty SimpleAlert:
     ```js
     {isAlertVisible && (
         <SimpleAlert
             message={alertMessage.text}
             onClose={closeAlert}
             type={alertMessage.type}
         />
     )}
     ```

3. **Zavolejte `showAlert` pro zobrazení upozornění:**
   - V reakci na události (například po chybě nebo úspěšné akci) zavolejte `showAlert`:
     ```js
     showAlert('Akce byla úspěšná!', 'success');
     ```

4. **Zavřete upozornění:**
   - Uživatel může zavřít alert kliknutím na ikonu zavření, což vyvolá funkci `onClose` a skryje upozornění.
*/
