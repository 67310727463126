import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'; // Importujte useNavigate a useParams

import he from 'he';

import permoniqLogo from "../img/logo-inverse.svg";
import icons from '../img/icons.svg'; // Import the SVG sprite

import Cookies from 'js-cookie';

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const FooterMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Přidejte useNavigate zde
  const { lng } = useParams(); // Přidejte useParams zde

  const saveLanguage = () => {
    let path = window.location.pathname;
    let pathSplit = path.split("/");
    let newLng = pathSplit[1];

    if (newLng !== "cs" &&
      newLng !== "en" &&
      newLng !== "hu" &&
      newLng !== "sk" &&
      newLng !== "pl" &&
      newLng !== "ro"
    ) {
      newLng = "en";
    }
    Cookies.set("lng", newLng);
  }

  const handleRedirect = (path) => {
    saveLanguage()
    navigate(path)
    window.scrollTo(0, 0);
  }

  return (
    <footer className="footer">
      <div className="box box--inverse">
        <div className="container">
          <div className="row row--start">
            <div className="col col--3 col--grow">
              <p className="footer__logo"><img src={permoniqLogo} alt="PermoniQ" width="151" height="32" loading="lazy" /></p>
              <p dangerouslySetInnerHTML={renderHTML(t('footer.company'))}></p>
              <p dangerouslySetInnerHTML={renderHTML(t('footer.contacts.address'))}></p>
              <p>
                <span className="nowrap" dangerouslySetInnerHTML={renderHTML(t('footer.contacts.ico'))}></span>
              </p>
            </div>
            <div className="col col--3">
              <p className="footer__contact">
                <span className="icon icon--phone">
                  <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use xlinkHref={`${icons}#phone`} x="0" y="0" width="100%" height="100%"></use>
                  </svg>
                </span>
                <a href={t("permoniqPhoneHref")}>{t('permoniqPhone')}</a>
                <small dangerouslySetInnerHTML={renderHTML(t('footer.contacts.hours'))}></small>
              </p>
              <p className="footer__contact">
                <span className="icon icon--email">
                  <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <use xlinkHref={`${icons}#email`} x="0" y="0" width="100%" height="100%"></use>
                  </svg>
                </span>
                <a href={`mailto:${t('footer.contacts.email')}`}>{t('footer.contacts.email')}</a>
              </p>
            </div>
            <div className="col col--4">
              <nav className="footer__nav">
                <ul>
                  <li>
                    <a
                      className="footer-href"
                      target="_blank"
                      href={`${window.location.origin}/${lng}/terms-of-service/`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleRedirect("/terms-of-service/");
                      }}
                      dangerouslySetInnerHTML={renderHTML(t('footer.terms'))}
                    ></a>
                  </li>
                  <li>
                    <a
                      className="footer-href"
                      target="_blank"
                      href={`${window.location.origin}/${lng}/privacy-policy/`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleRedirect("/privacy-policy/");
                      }}
                      dangerouslySetInnerHTML={renderHTML(t('footer.privacy'))}
                    ></a>
                  </li>
                  <li>
                    <a
                      className="footer-href"
                      target="_blank"
                      href={`${window.location.origin}/${lng}/google-terms/`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleRedirect("/google-terms/");
                      }}
                      dangerouslySetInnerHTML={renderHTML(t('footer.googleTerms'))}
                    ></a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col col--2 footer__copyright">
              <p dangerouslySetInnerHTML={renderHTML(t('footer.copyright'))}></p>
              <p dangerouslySetInnerHTML={renderHTML(t('footer.footerText'))}></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterMain;
