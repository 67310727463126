import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import he from 'he';

import { AppContext } from '../context/AppContext';

import cssLogo from "../img/logos/logo-google-comparison-partner-v.png";
import dataplusLogo from "../img/logos/logo-dataplus.svg";

// Import relativních SVG ikon
import growIcon from '../img/icons/grow.svg';
import shieldIcon from '../img/icons/shield.svg';
import meterIcon from '../img/icons/meter.svg';
import heartIcon from '../img/icons/heart.svg';
import publishIcon from '../img/icons/publish.svg';
import chatIcon from '../img/icons/chat.svg';

const renderHTML = (html) => {
    return { __html: he.decode(html) };
};

const BenefitsSection = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(AppContext);

    return (
        <section className="section" id={t(`benefits.id`)}>
            <article className="container">
                <h2 className="title" dangerouslySetInnerHTML={renderHTML(t('benefits.title'))}></h2>

                <div className="benefits">
                    <div className="box benefits__1">
                        <span className="icon icon--grow">
                            <ReactSVG
                                src={growIcon}
                                beforeInjection={(svg) => {
                                    svg.setAttribute("fill", "#22E06C")
                                    svg.setAttribute("width", "100%")
                                }}
                            />
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.grow.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.grow.description'))}></p>
                        <p>
                            <a href="https://www.advisio.cz/css-program/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=benefits_section" target="_blank" rel="noopener">
                                <img src={cssLogo} alt="logo Google Comparison Shopping Partner" width="100" height="19" />
                            </a>
                            <a
                                href="https://dataplus.advisio.cz/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=benefits_section"
                                target="_blank"
                                rel="noopener">
                                <img src={dataplusLogo} alt="logo DataPlus" width="100" height="19" />
                            </a>
                        </p>
                    </div>

                    <div className="box is-connected benefits__2">
                        <span className="icon icon--shield">
                            <ReactSVG
                             src={shieldIcon}
                               beforeInjection={(svg) => {
                                    svg.setAttribute("fill", "#22E06C")
                                    svg.setAttribute("width", "100%")
                                }}
                               />
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.shield.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.shield.description'))}></p>
                    </div>

                    <div className="box is-connected benefits__3">
                        <span className="icon icon--meter">
                            <ReactSVG
                             src={meterIcon}
                               beforeInjection={(svg) => {
                                    svg.setAttribute("fill", "#22E06C")
                                    svg.setAttribute("width", "100%")
                                }}
                               />
                        </span>
                        <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.meter.title'))}></h3>
                        <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.meter.description'))}></p>
                    </div>

                    <div className="box is-connected has-children benefits__4">
                        <div className="benefits__item">
                            <span className="icon icon--heart">
                                <ReactSVG
                                 src={heartIcon}
                                   beforeInjection={(svg) => {
                                    svg.setAttribute("fill", "#22E06C")
                                    svg.setAttribute("width", "100%")
                                }}
                                   />
                            </span>
                            <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.heart.title'))}></h3>
                            <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.heart.description'))}></p>
                        </div>

                        <div className="benefits__item">
                            <span className="icon icon--publish">
                                <ReactSVG
                                 src={publishIcon}
                                   beforeInjection={(svg) => {
                                    svg.setAttribute("fill", "#22E06C")
                                    svg.setAttribute("width", "100%")
                                }}
                                   />
                            </span>
                            <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.publish.title'))}></h3>
                            <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.publish.description'))}></p>
                        </div>

                        <div className="benefits__item">
                            <span className="icon icon--chat">
                                <ReactSVG
                                 src={chatIcon}
                                   beforeInjection={(svg) => {
                                    svg.setAttribute("fill", "#22E06C")
                                    svg.setAttribute("width", "100%")
                                }}
                                   />
                            </span>
                            <h3 dangerouslySetInnerHTML={renderHTML(t('benefits.items.chat.title'))}></h3>
                            <p dangerouslySetInnerHTML={renderHTML(t('benefits.items.chat.description'))}></p>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default BenefitsSection;
