import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AppContext } from '../context/AppContext';

import Cookies from 'js-cookie';

import NavbarMain from './NavbarMain';
import NavbarLogin from './NavbarLogin';
import NavBarApp from './NavbarApp';
import NavbarNotFound from "./NavbarNotFound"

const Header = () => {
    const location = useLocation();
    const { pathname } = location;

    const [isNotFound, setIsNotFound] = useState(false);

    const {
        setAppContentType,
        setActiveItem,
        navbarType,
        setNavbarType,
        setIsUserDataLoading
    } = useContext(AppContext);

    useLayoutEffect(() => {
        console.log("pathname", !!pathname.split("/")[2])
    }, [])

    // Nastavení header v případě, že je stránka 404
    useEffect(() => {
        if (Cookies.get("notfound")) {
            setIsNotFound(true);
        }
    }, []);

    const isLogin = pathname.includes("login");
    const isMainPage = !!pathname.split("/")[2]
    const isRegistrationAccount = pathname.includes("registration-account");
    const isShopOption = pathname.includes("shop-option");
    const isTermsOfService = pathname.includes("terms-of-service");
    const isPrivacyPolicy = pathname.includes("privacy-policy");
    const isGoogleTermsPage = pathname.includes("google-terms");
    const isPasswordRestore = pathname.includes("user-password-reset-confirm");
    const isPageNotFound = pathname.includes("404")

    // Registrace
    const isRegisterShop = pathname.includes("register-shop");
    const isCompanyDetails = pathname.includes("company-details");
    const isUploadProducts = pathname.includes("upload-products");
    const isConnectGoogle = pathname.includes("connect-google");
    const isInstallTrackingCode = pathname.includes("install-tracking-code");

    // Aplikace
    const isDashboard = pathname.includes("dashboard");
    const isStatistics = pathname.includes("statistics");
    const isCampaigns = pathname.includes("campaigns");
    const isCredit = pathname.includes("credit");
    const isSettings = pathname.includes("settings");
    const isHelp = pathname.includes("help");
    const isContactUs = pathname.includes("contact-us");

    // Blog
    const isBlog = pathname.includes("blog");

    // Nastavení navbarType na základě cesty
    useEffect(() => {
        if (
            isLogin || isShopOption || isRegistrationAccount ||
            isRegisterShop || isCompanyDetails || isUploadProducts ||
            isConnectGoogle || isInstallTrackingCode || isTermsOfService ||
            isPrivacyPolicy || isGoogleTermsPage || isBlog || isPasswordRestore
        ) {
            setNavbarType("login");
            sessionStorage.setItem("nt", "login")
        } else if (
            isDashboard || isStatistics || isCampaigns ||
            isCredit || isSettings || isHelp || isContactUs
        ) {
            setNavbarType("app");
            sessionStorage.setItem("nt", "app")
        } else if (!isMainPage){
            setNavbarType("main");
            sessionStorage.setItem("nt", "main")
        } else {
            setNavbarType("404");
            sessionStorage.setItem("nt", "404")
        }
    }, [
        isLogin, isShopOption, isRegistrationAccount, isRegisterShop,
        isCompanyDetails, isUploadProducts, isConnectGoogle, isInstallTrackingCode,
        isTermsOfService, isPrivacyPolicy, isGoogleTermsPage, isBlog, isPasswordRestore,
        isDashboard, isStatistics, isCampaigns, isCredit, isSettings, isHelp, isContactUs
    ]);

    useEffect(() => {
        const stateMapping = {
            dashboard: isDashboard,
            statistics: isStatistics,
            campaigns: isCampaigns,
            credit: isCredit,
            settings: isSettings,
            help: isHelp,
            "contact-us": isContactUs,
        };

        for (const [key, value] of Object.entries(stateMapping)) {
            if (value) {
                setActiveItem(key);
                setAppContentType(key);
                break;
            }
        }
    }, [isDashboard, isStatistics, isCampaigns, isCredit, isSettings, isHelp, isContactUs]);


    return (
        <div className='header'>
            {navbarType === "login" && <NavbarLogin />}
            {navbarType === "app" && <NavBarApp />}
            {navbarType === "main" && <NavbarMain />}
            {navbarType === "404" && <NavbarNotFound />}
        </div>
    );
};

export default Header;
