import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';

import { AppContext } from '../context/AppContext';


import AppMenu from '../components/AppMenu';
import AppDashboard from '../components/AppDashboard';
import AppStatistics from '../components/AppStatistics';
import AppCampaigns from '../components/AppCampaigns';
import AppCredit from '../components/AppCredit';
import AppSettings from '../components/AppSettings';
import AppHelp from '../components/AppHelp';
import AppContactUs from '../components/AppContactUs';

const AppContent = () => {
    const {
        appContentType,
    } = useContext(AppContext);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {appContentType === 'dashboard' && <title>Dashboard - PermoniQ</title>}
                {appContentType === "statistics" && <title>Statistiky - PermoniQ</title>}
                {appContentType === 'campaigns' && <title>Kampaně - PermoniQ</title>}
                {appContentType === "credit" && <title>Kredit - PermoniQ</title>}
                {appContentType === "settings" && <title>Nastavení - PermoniQ</title>}
                {appContentType === "help" && <title>Nápověda - PermoniQ</title>}
                {appContentType === "contact-us" && <title>Kontakt - PermoniQ</title>}
            </Helmet>
            <main className="content content--app">
                <section className="container">
                    <AppMenu />
                    {appContentType === 'dashboard' && <AppDashboard />}
                    {appContentType === "statistics" && <AppStatistics />}
                    {appContentType === 'campaigns' && <AppCampaigns />}
                    {appContentType === "credit" && <AppCredit />}
                    {appContentType === "settings" && <AppSettings />}
                    {appContentType === "help" && <AppHelp />}
                    {appContentType === "contact-us" && <AppContactUs />}
                </section>
            </main>
        </>
    );
};

export default AppContent;
