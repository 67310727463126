import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import LanguageWrapper, { allowedPaths } from './components/LanguageWrapper';
import RedirectWithSlash from './components/RedirectWithSlash';
import Layout from './components/Layout';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/cs/" replace />,
  },
  {
    path: "/:lng/",
    element: (
      <LanguageWrapper>
        <RedirectWithSlash>
          <Layout />
        </RedirectWithSlash>
      </LanguageWrapper>
    ),
    children: allowedPaths,
  },
]);

export default router;
