import axiosInstance from "../../api/AxiosConfig"

/**
 * Checks the billing status for a given UUID and updates local storage based on the result.
 *
 * This function makes an asynchronous POST request to check the billing setup status for a given UUID.
 * If the status is "approved", a specific key is set in the local storage. Otherwise, the key is removed if it exists.
 *
 * @param {string} uuid - The unique identifier for the entity whose billing setup needs to be checked.
 * @returns {Promise<void>} Resolves when the billing check and storage updates are complete. Logs errors to the console if the request fails.
 *
 * @example
 * // Example usage:
 * const uuid = "12345-abcde";
 * checkBilling(uuid).then(() => {
 *     console.log("Billing check completed");
 * }).catch((error) => {
 *     console.error("Error during billing check:", error);
 * });
 */
export const checkBilling = async (uuid) => {
    try {
        const checkBillingResponse = await axiosInstance.post(`shop/billing/${uuid}/`, {
            source_type: "google-ads"
        })

        if (checkBillingResponse.data[0]?.billingSetup?.status?.toLowerCase() === "approved") {
            localStorage.setItem("chck_bl_1", 1)
        } else {
            if (localStorage.getItem("chck_bl_1")) {
                localStorage.removeItem("chck_bl_1")
            }
        }
    } catch (error) {
        console.log("Check billing error: ", error)
    }
}