
import React from 'react';

const ButtonBtn = ({ className, children, ...props }) => {
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px"
    }}>
      <a className={`btn ${className}`} onClick={(e) => e.target.blur()} {...props}>
        {children || 'Tlačítko'}
      </a>
    </div>
  );
};

export default ButtonBtn;
