import React from 'react';
import Styles from '../../css/campaignStatusBarStyles.module.css';

const CapmaignStatusBar = ({ children, value }) => {
    const progressBarColor = value >= 30 ? '#22e06c' : '#ffda44';

    return (
        <span className={Styles.campaign__status}>
            <div
                className={Styles.progress__fill}
                style={{
                    '--value': value,
                    backgroundColor: progressBarColor,
                }}
            ></div>
            <em>{children}</em>
        </span>
    );
};

export default CapmaignStatusBar;
