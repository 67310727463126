import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../context/AppContext'; // Upravte podle skutečné cesty k AppContext
import { useTranslation } from 'react-i18next';

const ProgressTracker = () => {
    const navigate = useNavigate();
    const { lng } = useParams();
    const { t } = useTranslation();
    const location = useLocation();

    const status = {
        step1: `/${lng}/app/register-shop/`,
        billinfo: `/${lng}/app/company-details/`,
        shopdata: `/${lng}/app/upload-products/`,
        account: `/${lng}/app/connect-google/`,
        pixel: `/${lng}/app/install-tracking-code/`,
    }

    const {
        selectedShop,
        showAlert,
        closeAlert,
        alertMessage,
        steps,
        isAdmin
    } = useContext(AppContext);

    // Získání aktuální cesty
    const currentPath = location.pathname;

    useEffect(() => {
        if (selectedShop) {
            if (Object.keys(selectedShop).length > 0) {
                console.log(selectedShop.status_create)
                console.log(location.pathname)
            }
        }
    }, [selectedShop])

    return (
        <div className="progress">
            <div className="container">
                <ul>
                    {steps.map((step, index) => {
                        const isActive = currentPath === `/${lng}${step.path}`;
                        const isChecked = index < steps.findIndex(s => `/${lng}${s.path}` === currentPath);

                        return (
                            <li
                                key={index}
                                onClick={isAdmin ? () => navigate(`/${lng}${step.path}`) : () => showAlert(t(step.alertMessage))}
                                className={isActive ? 'is-active' : isChecked ? 'is-checked' : ''}
                            >
                                <strong>{index + 1}.</strong> {t(step.label)}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default ProgressTracker;
