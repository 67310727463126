import React from 'react';
import { useTranslation } from 'react-i18next';
import advisioLogo from "../img/logo-advisio.svg"

const AdvisioSection = () => {
    const { t } = useTranslation();
    return (
        <section className="section">
            <article className="container">
                <h2 className="title">{t('advisioSection.title')}</h2>
                <div className="about">
                    <div className="box">
                        <h3>{t('advisioSection.whoTitle')}</h3>
                        <p>{t('advisioSection.whoText')}</p>
                        <p>
                            <a
                                href="https://www.advisio.cz/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=advisio_section"
                                className="btn"
                                target="_blank"
                                rel="noopener noreferrer" >
                                {t('advisioSection.moreAbout')}
                            </a>
                        </p>
                    </div>
                    <div className="box is-connected">
                        <p><img src={advisioLogo} alt="logo Advisio" width="204" height="41" loading="lazy" /></p>
                        <ul>
                            <li><strong>11</strong> {t('advisioSection.yearsOnMarket')}</li>
                            <li><strong>300+</strong> {t('advisioSection.satisfiedClients')}</li>
                            <li><strong>75</strong> {t('advisioSection.digitalAdvisors')}</li>
                        </ul>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default AdvisioSection;
