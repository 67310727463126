import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { AppContext } from '../context/AppContext';

import useUserDetails from '../hooks/useUserDetails';

import Cookies from "js-cookie"

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";
import plusIcon from "../img/icons/plus.svg";
import loginIcon from "../img/icons/login.svg";
import inverseLogo from "../img/logo-inverse.svg";
import loginLogo from "../img/icons/login.svg";

import { TbLogout2 } from "react-icons/tb";

import { clearUserDetailDB } from '../api/AxiosConfig';

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const NavBarApp = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation()

  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const languageRef = useRef(null);
  const logoutRef = useRef(null);
  const accountButtonRef = useRef(null);
  const addShopRef = useRef(null)
  const [selectedLanguage, setSelectedLanguage] = useState(lng || 'cs');
  const [showLogout, setShowLogout] = useState(false);

  const {
    userData,
    setIsUserDataLoading,
    setVisitedInstallTrackingCode,
    appContentType,
    isLogged
  } = useContext(AppContext);

  useUserDetails()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language.toLowerCase());
    setSelectedLanguage(language.toLowerCase());
    setIsLanguageSwitchVisible(false);
    navigate(`/${language.toLowerCase()}/app/${appContentType}/`);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    const userToken = Cookies.get('rTkn_4zF7P');

    if (!userToken) {
      navigate(`/${lng}/login/`, { replace: true });
      return;
    }

    if (userData?.shops?.length === 1) {
      const shop = userData.shops[0];
      if (shop.status_create !== 'end') {
        navigate(`/${lng}/app/register-shop/`, { replace: true });
        return;
      }
    } else if (Object.keys(userData?.shops).length === 0) {
      navigate(`/${lng}/app/register-shop/`, { replace: true });
      return;
    } else if (Object.keys(userData?.shops).length > 1) {
      navigate(`/${lng}/app/shop-option/`, { replace: true });
      return;
    }

    navigate(isLogged ? `/${lng}/app/${appContentType}/` : `/${lng}/login/`, { replace: true });
  };

  const handleClickOutside = (event) => {
    if (
      logoutRef.current &&
      !logoutRef.current.contains(event.target) &&
      accountButtonRef.current &&
      !accountButtonRef.current.contains(event.target)
    ) {
      setShowLogout(false);
    }

    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const changeVisitedInstallTrackingState = () => {
    setVisitedInstallTrackingCode(false)
  }


  // Funkce pro přepnutí viditelnosti okénka
  const handleToggleLogout = () => {
    setShowLogout(!showLogout);
  };

  const onLogout = () => {
    setIsUserDataLoading(true)
    Cookies.remove("rTkn_4zF7P")
    Cookies.remove("aTkn_0yD9K")
    Cookies.remove("utk_98x76")
    clearUserDetailDB()
    navigate(`/${lng}/login/`)
    window.location.reload(true)
  }

  return (
    <header className="header app-navbar" data-navbar="not_found">
      <div className="container">
        <div className="header__logo">
          <Link to={`${isLogged ? `/${lng}/app/dashboard/` : `/${lng}/`}`}>
            <img src={inverseLogo} alt="PermoniQ" width="151" height="32" />
          </Link>
        </div>
        <nav className="app-nav-header">
          <div className="select-main">
          </div>
          <ul>
            <li className="nav__login" style={{ cursor: "pointer" }}>
              {isLogged ? (
                <a onClick={handleToggleLogout} ref={accountButtonRef}>
                  <span className="icon icon--login">
                    <ReactSVG src={loginLogo} className='icon__svg' />
                  </span>
                  {t('nav.myAccount')}
                </a>
              ) : (
                <a onClick={handleLoginClick}>
                  <span className="icon icon--login">
                    <ReactSVG src={loginLogo} className='icon__svg' />
                  </span>
                  {t('nav.login')}
                </a>
              )}

              {/* Zobrazení odhlašovacího okénka */}
              {showLogout && isLogged && (
                <div className="logout-popup" ref={logoutRef} style={{ position: "fixed" }}>
                  <p className='logout-name'>{userData?.email}</p>
                  <button className='logout-btn' onClick={handleLoginClick}>{t('nav.app')}</button>
                  <button className='logout-btn' onClick={onLogout}>{t('nav.logout')}</button>
                </div>
              )}
            </li>
          </ul>
          <div className="language" ref={languageRef}>
            <a href="" onClick={toggleLanguageSwitch}>
              <img
                src={flags[selectedLanguage]}
                alt={t(`loginLanguagesAppNavBar.${selectedLanguage}`)}
              />
            </a>
            <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
              {Object.keys(flags).map((lang) => (
                <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                  <img
                    src={flags[lang.toLowerCase()]}
                    alt={t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)}
                  />{' '}
                  {t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)}
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavBarApp;
