import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

const appVersion = process.env.REACT_APP_VERSION

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "cs",
        detection: {
            order: ['path', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
            caches: ["cookie"],
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?v=${appVersion}`
        },
        react: {
            useSuspense: true, //nastaveno true pro rychlejší zobrazení překladu
        },
    })

export default i18n