import React, { useEffect } from 'react';

const ModalInfo = ({ isOpen, toggleModal, t }) => {

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                toggleModal();
            }
        };

        if (isOpen) {
            window.addEventListener('keydown', handleEsc);
            document.body.style.overflow = "hidden"
        } else {
            window.removeEventListener('keydown', handleEsc);
            document.body.style.overflow = "auto"
        }

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [isOpen, toggleModal]);

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-overlay" onClick={toggleModal}>
                <button className="close-icon" onClick={toggleModal} aria-label="Close modal">
                    &times;
                </button>
            </div>
            <dialog className="modal-last30days" open>
                <div role="document" className="modal-content-last30days">
                    <main className="modal-body">
                        <section id='order__count__explain' className="modal-card">
                            <h3>{t("statisticsModal.orderCountTitle")}</h3>
                            <p>{t("statisticsModal.orderCountDescription")}</p>
                        </section>

                        <section id='pno__explain' className="modal-card">
                            <h3>{t("statisticsModal.pnoTitle")}</h3>
                            <p>{t("statisticsModal.pnoDescription")}</p>
                            <h4>{t("statisticsModal.pnoCalculationTitle")}</h4>
                            <p>{t("statisticsModal.pnoCalculationFormula")}</p>
                            <h4>{t("statisticsModal.pnoExampleTitle")}</h4>
                            <p>{t("statisticsModal.pnoExample")}</p>
                            <p>{t("statisticsModal.pnoExampleDescription")}</p>
                        </section>

                        <section id='click__count__explain' className="modal-card">
                            <h3>{t("statisticsModal.clickCountTitle")}</h3>
                            <p>{t("statisticsModal.clickCountDescription")}</p>
                        </section>

                        <section id='visitor__count__expolain' className="modal-card">
                            <h3>{t("statisticsModal.visitorCountTitle")}</h3>
                            <p>{t("statisticsModal.visitorCountDescription")}</p>
                        </section>

                        <section id='total__expenses__explain' className="modal-card">
                            <h3>{t("statisticsModal.totalExpensesTitle")}</h3>
                            <p>{t("statisticsModal.totalExpensesDescription")}</p>
                        </section>

                        <section id='generated__revenue__explain' className="modal-card">
                            <h3>{t("statisticsModal.generatedRevenueTitle")}</h3>
                            <p>{t("statisticsModal.generatedRevenueDescription")}</p>
                        </section>

                        <section id='assisted__revenue__explain' className="modal-card">
                            <h3>{t("statisticsModal.assistedRevenueTitle")}</h3>
                            <p>{t("statisticsModal.assistedRevenueDescription")}</p>
                        </section>

                        <section id='conversion__ratio__explain' className="modal-card">
                            <h3>{t("statisticsModal.conversionRatioTitle")}</h3>
                            <p>{t("statisticsModal.conversionRatioDescription")}</p>
                            <h4>{t("statisticsModal.conversionRatioExampleTitle")}</h4>
                            <p>{t("statisticsModal.conversionRatioExample")}</p>
                        </section>

                    </main>
                </div>
            </dialog>
        </>
    );
};

export default ModalInfo;

// vždy p
