import React, { useContext, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import ModalLoading from "./ModalLoading"
import { AppContext } from '../context/AppContext';
import NoPNOWarning from './NoPNOWarning';

const Layout = () => {
  const {
    isUserDataLoading,
    showPNOWarning,
    setIsUserDataLoading
  } = useContext(AppContext)

  return (
    <div>
      {isUserDataLoading && <ModalLoading />}

      <header className='header'>
        <Header />
      </header>
      <main className='content'>
        {/* {showPNOWarning && <NoPNOWarning />} momentálně se tato zpráva o potřebě nastavení PNO a rozpočtu nepoužívá */} 
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
