import React, { useEffect, useRef, useState, useContext } from 'react';
import ModalForgottenPasswordStyles from "../css/ModalForgottenPassword.Styles.module.css";

import { AppContext } from '../context/AppContext';

import SimpleAlert from './SimpleAlert';

import Loading from './Loading';

const ModalForgottenPassword = ({ isOpen, toggleModal, t }) => {
    const dialogRef = useRef(null);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const {
        showAlert,
        closeAlert,
        isAlertVisible,
        setIsAlertVisible,
        alertMessage,
    } = useContext(AppContext);

    useEffect(() => {
        if (isOpen) {
            dialogRef.current.showModal();
            document.body.style.overflow = "hidden";
        } else {
            dialogRef.current.close();
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onRequestNewPassword = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (email.length === 0) {
            showAlert(t("modalForgottenPassword.errorMessages.emptyMailErrorMsg"), "danger");
            return;
        } else if (!validateEmail(email)) {
            showAlert(t("modalForgottenPassword.errorMessages.notValidMailErrorMsg"), "danger");
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`https://permoniq.com/api/password-reset/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (!response.ok) {
                console.log("Chyba:", data);
                if (data.email && data.email[0] && data.email[0] === "User with this email does not exist.") {
                    showAlert(t("modalForgottenPassword.errorMessages.userNotExistErrorMsg"), "danger");
                }
                throw new Error('Network response was not ok');
            }

            showAlert(t("modalForgottenPassword.infoMessages.emailSentInfoMsg"), "info");
            setEmail("");
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <dialog ref={dialogRef} className={ModalForgottenPasswordStyles.forgottenPasswordDialog} onClick={(e) => e.stopPropagation()}>
                {isAlertVisible && (
                    <SimpleAlert
                        message={alertMessage.text}
                        type={alertMessage.type}
                        onClose={closeAlert}
                    />
                )}
                <div className={ModalForgottenPasswordStyles.dialogWrapper}>
                    <button className="close-icon" onClick={toggleModal} aria-label="Close modal">
                        &times;
                    </button>
                    <p className={ModalForgottenPasswordStyles.infoBox}>{t("modalForgottenPassword.infoText")}</p>
                    <label htmlFor="requested_mail">{t("modalForgottenPassword.inputLabel")}</label>
                    <input
                        type="email"
                        name="email"
                        id="requested_mail"
                        className={ModalForgottenPasswordStyles.inputForNewPass}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className={ModalForgottenPasswordStyles.btnModalWrapper}>
                        <input type="button" className={`btn ${ModalForgottenPasswordStyles.btn}`} onClick={toggleModal} value={t('descriptionModal.close')} />
                        <input
                            type="button"
                            className={`btn ${ModalForgottenPasswordStyles.btn}`}
                            onClick={(e) => onRequestNewPassword(e)}
                            value={t('modalForgottenPassword.requestNewPassword')}
                            disabled={isLoading}
                        />
                    </div>
                    {isLoading && (
                        <div className={ModalForgottenPasswordStyles.overlay}>
                            <Loading small={true} />
                        </div>
                    )}
                </div>
            </dialog>
        </>
    );
};

export default ModalForgottenPassword;
