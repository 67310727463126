import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: 'https://permoniq.com/api/', // Base URL of your API
  headers: {
    'Content-Type': 'application/json',
  },
});

function openIndexedDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('UserDatabase', 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('userDetailStore')) {
        db.createObjectStore('userDetailStore');
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

function getUserDetailFromDB() {
  return new Promise(async (resolve, reject) => {
    const db = await openIndexedDB();
    const transaction = db.transaction('userDetailStore', 'readonly');
    const store = transaction.objectStore('userDetailStore');
    const request = store.get('userData');

    request.onsuccess = () => {
      console.log("data from DB", request.result)
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
}

function setUserDetailInDB(data) {
  return new Promise(async (resolve, reject) => {
    const db = await openIndexedDB();
    const transaction = db.transaction('userDetailStore', 'readwrite');
    const store = transaction.objectStore('userDetailStore');
    const request = store.put(data, 'userData');

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
}

function clearUserDetailDB() {
  return new Promise(async (resolve, reject) => {
    try {
      const dbRequest = indexedDB.deleteDatabase('UserDatabase');

      dbRequest.onsuccess = () => {
        console.log('Databáze UserDatabase byla úspěšně smazána.');
        resolve();
      };

      dbRequest.onerror = () => {
        console.error('Chyba při mazání databáze:', dbRequest.error);
        reject(dbRequest.error);
      };

      dbRequest.onblocked = () => {
        console.warn('Mazání databáze bylo zablokováno, zavřete prosím ostatní spojení k databázi.');
        reject(new Error('Mazání databáze bylo zablokováno.'));
      };
    } catch (error) {
      reject(error);
    }
  });
}


axiosInstance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('aTkn_0yD9K');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      const lng = Cookies.get("i18next") || "cs";
      window.location.href = `/${lng}/login/`;

      setTimeout(() => {
        const messages = {
          cs: "Došlo k chybě, zkuste se znovu přihlásit!",
          en: "An error occurred, try to login again!",
          pl: "Wystąpił błąd, spróbuj ponownie się zalogować!",
          hu: "Hiba történt, próbáljon újra bejelentkezni!",
          sk: "Došlo k chybe, skúste sa znova prihlásiť!",
          ro: "A apărut o eroare, încercați să vă conectați din nou!",
        };
        alert(messages[lng] || messages.en);
      }, 500);
    }

    if (config.method === "get" && config.url.includes("user/detail/")) {
      const cachedData = await getUserDetailFromDB();
      if (cachedData) {
        return Promise.reject({
          isCached: true,
          cachedData,
        });
      }
    }

    if ((config.method === "post" || config.method === "patch" || config.method === "put") && config.url.includes("update")) {
      await clearUserDetailDB();
    } else if (config.method === "post" && config.url.includes("shop/create/")) {
      await clearUserDetailDB();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    if (response.config.method === "get" && response.config.url.includes("user/detail/")) {
      await setUserDetailInDB(response.data);
    }
    return response;
  },
  (error) => {
    if (error.isCached) {
      return Promise.resolve({ data: error.cachedData });
    }
    return Promise.reject(error);
  }
);

export { clearUserDetailDB }
export default axiosInstance;
