/**
 * Cleans a domain string by removing the protocol (http:// or https://).
 * 
 * @param {string} domain - The domain string to clean. This should include the protocol (e.g., "https://example.com").
 * @throws {TypeError} Throws an error if the "domain" parameter is not a string.
 * @returns {string} The domain string without the protocol prefix.
 * 
 * @example
 * // Example usage:
 * const cleaned = cleanDomain('https://example.com');
 * console.log(cleaned); // Output: 'example.com'
 * 
 * const cleaned2 = cleanDomain('http://example.com');
 * console.log(cleaned2); // Output: 'example.com'
 */
export function cleanDomain(domain) {
    if (typeof domain !== 'string') {
        throw new TypeError('The "domain" parameter must be a string');
    }

    const cleanedDomain = domain.replace(/^(https?:\/\/)/, "");
    return cleanedDomain;
}
