import React, { useContext, useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import axiosInstance from "../api/AxiosConfig";

import { AppContext } from "../context/AppContext";

import Cookies from "js-cookie";

const useUserDetails = () => {
    const navigate = useNavigate()
    const { lng } = useParams()
    const location = useLocation()

    const {
        isLogged,
        setIsUserDataLoading,
        setUserData,
        isAdmin,
        setIsAdmin,
        appContentType,
        setShowPNOWarning
    } = useContext(AppContext)

    // useEffect(() => {
    //     // Odstranění cookies pro 404
    //     Cookies.remove("notfound")
    // }, [])

    useEffect(() => {
        if (isLogged) {
            fetchUserData()
        } else {
            setIsUserDataLoading(false)
        }
    }, [isLogged])

    useEffect(() => {
        setShowPNOWarning(false)
    }, [])

    const fetchUserData = async () => {
        // setIsUserDataLoading(true)

        try {
            const response = await axiosInstance.get('user/detail/');

            if (location.pathname !== `/${lng}/`) {
                if (Object.keys(response?.data?.shops).length === 0) {
                    navigate(`/${lng}/app/register-shop/`)
                } else if (response?.data?.email !== "info@permoniq.com" && Object.keys(response?.data?.shops).length === 1 && response?.data?.shops[0].status_create !== "end") {
                    navigate(`/${lng}/app/register-shop/`)
                } else if (response?.data?.email !== "info@permoniq.com" && Object.keys(response?.data?.shops).length === 1 && response?.data?.shops[0].status_create === "end") {
                    navigate(`/${lng}/app/${appContentType}/`)
                } else if (Object.keys(response?.data?.shops).length > 1 && !Cookies.get("notfound")) {
                    navigate(`/${lng}/app/shop-option/`)
                } else {
                    console.log("404")
                }
            }

            setUserData(response.data)

            if (response?.data?.email === "info@permoniq.com") {
                setIsAdmin(true)
            }

        } catch (error) {
            if (error.response.data.code === "user_not_found") {
                alert("Tento uživatel neexistuje!")
                // Cookies.remove("rTkn_4zF7P")
                // Cookies.remove("aTkn_0yD9K")
                navigate(`/${lng}/login/`)
                window.location.reload(true)
            } else {
                console.error('Error fetching user details:', error);
            }
        } finally {
            Cookies.remove("notfound")

            setTimeout(() => {
                setIsUserDataLoading(false)
            }, 3000);
        }
    };
}

export default useUserDetails