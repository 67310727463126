import axiosInstance from "../../api/AxiosConfig";

/**
 * Sends an error message to the server with details about an application error.
 *
 * This function formats and sends an error message using the provided user data, 
 * optional shop details, and a specific error message. It posts the message to 
 * a designated endpoint via an Axios instance.
 *
 * @async
 * @function
 * @param {Object} userData - The user information.
 * @param {string} userData.email - The email address of the user reporting the error.
 * @param {Object} [selectedShop=false] - Details of the shop where the error occurred (optional).
 * @param {string} [selectedShop.website] - The website of the shop (if available).
 * @param {string} [selectedShop.name] - The name of the shop (if available).
 * @param {string} [selectedShop.phone] - The phone number of the shop (if available).
 * @param {string} [selectedShop.uuid] - The unique identifier (UUID) of the shop (if available).
 * @param {string} errorMessage - A description of the error to be included in the message.
 * @returns {Promise<Object|undefined>} A promise that resolves to the server's response data on success.
 * It returns `undefined` if the request fails or encounters an error.
 *
 * @throws Will log an error to the console if the request fails.
 *
 * @example
 * // Usage example:
 * import { sendErrorMessage } from './path/to/your/module';
 * 
 * const userData = { email: "user@example.com" };
 * const shopDetails = { 
 *     website: "https://shopwebsite.com", 
 *     name: "Shop Name", 
 *     phone: "+123456789", 
 *     uuid: "abc-123-xyz" 
 * };
 * const errorMessage = "An unexpected error occurred.";
 * 
 * sendErrorMessage(userData, shopDetails, errorMessage)
 *     .then((response) => {
 *         console.log("Error message sent successfully:", response);
 *     })
 *     .catch((error) => {
 *         console.error("Failed to send error message:", error);
 *     });
 */
export const sendErrorMessage = async (userData, selectedShop = false, errorMessage) => {
    let errorMessageObject = {}
    if (selectedShop) {
        errorMessageObject = {
            email: userData.email,
            subject: "app error",
            message: `
                    U tohoto obchodu došlo k chybě<br>
                    e-mail: ${userData?.email}<br>
                    website: ${selectedShop?.website || "Pro tento obchod ještě nebyl přidán web."}<br>
                    name: ${selectedShop?.name || "Pro tento obchod ještě nebyl přidán název."}<br>
                    phone: ${selectedShop?.phone || "Pro tento obchod ještě nebylo přidáno telefonní číslo."}<br>
                    UUID: ${selectedShop?.uuid || "Pro tento obchod ještě nebylo vytvořeno UUID."}<br>
                    time: ${new Date().toLocaleString("cs-CZ")}<br>
                    URL: ${window.location.pathname}<br>
                    error: ${errorMessage}
                `,
            send_email: true,
            country: window.location.pathname.split("/")[1],
        }
    } else {
        errorMessageObject = {
            email: userData.email,
            subject: "app error",
            message: `
                    U tohoto obchodu došlo k chybě<br>
                    e-mail: ${userData?.email}<br>
                    time: ${new Date().toLocaleString("cs-CZ")}<br>
                    URL: ${window.location.pathname}<br>
                    error: ${errorMessage}
                `,
            send_email: true,
            country: window.location.pathname.split("/")[1],
        }
    }

    try {
        const response = await axiosInstance.post(`send-message/`, errorMessageObject);
        return response.data;
    } catch (error) {
        console.error("Failed to send error message: ", error.message || error);
        // throw new Error('Failed to send the error message. Please try again.');
    }
}