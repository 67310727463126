import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from '../img/icons.svg'; // Import the SVG sprite
import he from 'he';

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const FooterLogin = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer footer--app">
      <div className="container">
        <p className="footer__contact">
          <span className="icon icon--phone">
            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <use xlinkHref={`${icons}#phone`} x="0" y="0" width="100%" height="100%"></use>
            </svg>
          </span>
          <a href={t("permoniqPhoneHref")}>{t('permoniqPhone')}</a>
          <small dangerouslySetInnerHTML={renderHTML(t('footer.contacts.hours'))}></small>
        </p>
        <p className="footer__contact">
          <span className="icon icon--email">
            <svg className="icon__svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <use xlinkHref={`${icons}#email`} x="0" y="0" width="100%" height="100%"></use>
            </svg>
          </span>
          <a href={`mailto:${t('footer.contacts.email')}`}>{t('footer.contacts.email')}</a>
        </p>
      </div>
    </footer>
  );
};

export default FooterLogin;
